import { Component, createEffect, For } from 'solid-js'

interface Props {
	pageSize: number
}

const ListSkeleton: Component<Props> = props => {
	return (
		<For each={[...Array(props.pageSize)]}>
			{() => <div class="h-28 w-full animate-pulse bg-brightgrey md:h-28 xl:h-32" />}
		</For>
	)
}

export default ListSkeleton
