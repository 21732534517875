import { Accessor, batch, Component, createEffect, createSignal, Show } from 'solid-js'
import { CareerSearchQuery, CareerSearchQueryVariables } from '@/shared/generated/graphql'
import Multiselect, { DropdownData } from '../../../shared/components/Multiselect'
import { CareerStore } from '../career-list'
import { useGraphQL } from '@/shared/context/GraphQLContext'
import { createStore } from 'solid-js/store'
import { CAREER_SEARCH } from '@/shared/queries/career'
import SearchBar, { SearchableItem } from '../../../shared/components/SearchBar'
import { debounce } from '@solid-primitives/scheduled'
import IconClear from '@/assets/icons/career/BWEGT_CAREER_CLEAR.svg'
import { DEFAULT_DISTANCE } from '../shared/filter-configuration'
import { useEventBus } from '../context/EventBusContext'
import Header from '../../../shared/components/Header'
import waitForElm from '@/shared/utils/mutationObserver'

export type SearchQuery = {
	query: string
	plz: string
	distance: number
	categories: string[]
}

type Props = {
	onSearch: (data: SearchQuery) => void
	onFilterChange: (path: string, items: DropdownData[]) => void
	store: CareerStore
	redirectsearch?: string
	searchheader?: string
	searchsubheader?: string
	searchquery?: string
	jobtitle?: string
	headerlayout?: number
}

const Search: Component<Props> = props => {
	const newQuery = useGraphQL()

	const eventBus = useEventBus()

	const [searchStore, setSearchStore] = createStore<SearchQuery>({
		// eslint-disable-next-line solid/reactivity
		query: props.store.query,

		// eslint-disable-next-line solid/reactivity
		plz: props.store.plz,

		// eslint-disable-next-line solid/reactivity
		distance: props.store.distance,

		// eslint-disable-next-line solid/reactivity
		categories: props.store.filters.find(x => x.path === 'categories.title')?.options ?? [],
	})

	const [suggestInput, setSuggestInput] = createSignal<CareerSearchQueryVariables | false>(false)

	// eslint-disable-next-line solid/reactivity
	const [suggestData] = newQuery<CareerSearchQuery, CareerSearchQueryVariables>(CAREER_SEARCH, () => suggestInput())
	const suggestItems: Accessor<SearchableItem[]> = () =>
		suggestData()?.suggestPostings.map(item => ({ name: item, id: item })) ?? []

	// eslint-disable-next-line solid/reactivity
	eventBus.listen(event => {
		// When the search need to be triggered from another component, we need to trigger the search manually
		if (event.type === 'searchTriggered') {
			props.onSearch({ ...searchStore })
			scrollToList()
		}
	})

	const scrollToList = () => {
		// Scroll to list when mobile
		if (window.matchMedia('(max-width: 992px)').matches) {
			waitForElm('.career-list-header', 5000).then((elm: HTMLElement) => {
				elm.scrollIntoView({ behavior: 'smooth' })
			})
		}
	}

	const setSearch = (key: keyof SearchQuery, value: string | number) => {
		if (key === 'query' && props.store.query !== value) {
			setSearchStore('query', value as string)

			if (value === '') {
				// Trigger search because we want to have it delete the search immediately
				props.onSearch({ ...searchStore })
			}
			return
		}

		if (key === 'plz') {
			if (value === '') {
				batch(() => {
					setSearchStore('plz', '')
					setSearchStore('distance', DEFAULT_DISTANCE)
				})
				// Trigger search because we need to sync the ui state back to the state we just set
				props.onSearch({ ...searchStore })
				return
			}
			setSearchStore('plz', value as string)
			return
		}

		if (key === 'distance') {
			setSearchStore('distance', value as number)
			if (value === DEFAULT_DISTANCE && props.store.distance !== value) {
				props.onSearch({ ...searchStore })
			}
			return
		}
	}

	const categoryFacets = (): DropdownData[] =>
		(
			props.store.facets as Array<{
				__typename?: 'DiscreteFacet'
				label: string
				path: string
				options: Array<{
					__typename?: 'FilterOption'
					selected: boolean
					value: string
					resultCount: number
					disabled: boolean
				}>
			}>
		)
			.find(facet => facet.path === 'categories.title')
			?.options.map(facet => ({
				name: facet.value,
				value: facet.value,
				selected:
					props.store.filters
						?.find(filter => filter.path === 'categories.title')
						?.options?.some(option => option === facet.value) ?? false,
				disabled: facet.disabled,
			})) ?? []

	// Sync the selected options from the other category facet over to this one
	createEffect(
		() => {
			setSearchStore(
				'categories',
				categoryFacets()
					.filter(x => x.selected)
					.map(x => x.value)
			)
		},
		{ defer: true }
	)

	// Sync the search state from the store to the search component
	createEffect(() => setSearchStore('query', props.store.query), { defer: true })
	createEffect(() => setSearchStore('plz', props.store.plz), { defer: true })
	createEffect(() => setSearchStore('distance', props.store.distance), { defer: true })

	// eslint-disable-next-line solid/reactivity
	const onSearch = debounce((query: string, suggest: boolean = true) => {
		if (suggest) {
			setSuggestInput({ search: query })
		}
		setSearch('query', query)
	}, 100)

	const onCategorySelect = (value: DropdownData[]) => {
		if (value.length === 0) {
			setSearchStore('categories', [])
			props.onSearch({ ...searchStore })
			return
		}

		setSearchStore(
			'categories',
			value.map(x => x.value)
		)
	}

	const onSubmit = (e: SubmitEvent, query: string = searchStore.query): void => {
		e.preventDefault()
		setSearchStore('query', query)
		props.onSearch({ ...searchStore })
		if (e instanceof SubmitEvent && e.submitter?.id === 'career-form-submit-btn') {
			scrollToList()
		}

		if (props.redirectsearch) {
			let newUrl
			try {
				newUrl = new URL(props.redirectsearch)
			} catch {
				newUrl = new URL(window.location.origin + props.redirectsearch)
			}
			newUrl.search = props.store.generateStateQueryParams()
			location.href = newUrl.toString()
		}
	}

	// determine whether first suggestion request is needed (url params loaded)
	// eslint-disable-next-line solid/reactivity
	if (props.store.query.trim() !== '') {
		// eslint-disable-next-line solid/reactivity
		setSuggestInput({ search: props.store.query })
	}

	return (
		<div class="career-search" classList={{ 'search-only': props.redirectsearch != '' }}>
			<Show
				when={!props.searchheader && props.jobtitle}
				fallback={<Header type={props.headerlayout}>{props.searchheader}</Header>}>
				<h2 class="title">
					Wir haben {props.store.postingsPagination.isLoading ? '' : props.store.postingsPagination.total} Jobs{' '}
					{props.jobtitle} bei unseren Partnern für dich gefunden
				</h2>
			</Show>

			<Show when={props.searchsubheader}>
				<p class="subtitle text-center !not-italic">{props.searchsubheader}</p>
			</Show>
			<form class="inputs" aria-label="Suchformular für Jobs" onSubmit={onSubmit}>
				<div class="searchbar">
					<SearchBar
						id="career-search"
						name="career-search"
						inputClass="field search"
						placeholder="Jobtitel"
						items={suggestItems()}
						onSearch={(query: string, suggest: boolean) => onSearch(query, suggest)}
						onSelect={item => setSearchStore('query', item.name)}
						onSubmit={(e: Event, query: string) => onSubmit(e as SubmitEvent, query)}
						loading={suggestData.loading}
						aria-listbox-label="Vorschläge"
						aria-label="Jobtitel"
						value={props.store.query}
					/>
				</div>
				<div class="second-row">
					<Multiselect
						data={categoryFacets()}
						settings={{
							multiSelect: true,
							searchField: true,
							closeOnSingleSelect: false,
							showSelected: true,
							showClear: true,
							noOptionsLabel: 'Keine Auswahlmöglichkeit für deine Suchanfrage.',
						}}
						label={{ search: 'Suche', button: 'Berufsfeld', noSelection: 'Keine Berufe gefunden' }}
						selectedItems={onCategorySelect}
					/>
					<div class="field place-radius">
						<Show when={searchStore.plz !== ''}>
							<IconClear
								onClick={e => {
									e.stopPropagation()
									;(document.getElementById('career-plz') as HTMLInputElement).value = ''
									setSearch('plz', '')
								}}
								class="absolute right-[calc(50%+1rem)] top-[50%] z-[1] translate-y-[-50%] transform cursor-pointer"
								width="12.262"
								height="12.262"
							/>
						</Show>
						<input
							id="career-plz"
							type="text"
							class="place"
							placeholder="Ort"
							aria-label="Ort"
							required={searchStore.distance !== DEFAULT_DISTANCE}
							onInput={() => {
								setSearchStore('plz', (document.querySelector('.place') as HTMLInputElement).value)
							}}
							value={searchStore.plz}
						/>
						<div class="radius">
							<Multiselect
								data={[
									{ name: '5 km', value: '5', disabled: false, selected: searchStore.distance === 5 },
									{ name: '30 km', value: '30', disabled: false, selected: searchStore.distance === 30 },
									{ name: '50 km', value: '50', disabled: false, selected: searchStore.distance === 50 },
									{ name: '100 km', value: '100', disabled: false, selected: searchStore.distance === 100 },
								]}
								settings={{
									multiSelect: false,
									showSelected: true,
									closeOnSingleSelect: true,
									searchField: false,
									sort: false,
									showClear: true,
								}}
								label={{
									search: '',
									button: 'Umkreis',
									noSelection: '',
								}}
								styleClasses={{
									dropdownListScrollContainer: 'dropdown-content-scrollcontainer-single',
									dropdownListItemCheckbox: 'dropdown-checkbox-single',
								}}
								selectedItems={items => {
									if (items.length === 0) {
										setSearch('distance', DEFAULT_DISTANCE)
										return
									}

									setSearch('distance', parseInt(items[0]?.value))
								}}
							/>
						</div>
					</div>
					<button id="career-form-submit-btn" type="submit" class="search-btn">
						<svg aria-hidden="true" viewBox="0 0 512 512">
							<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#ios-search-strong" />
						</svg>
						<span>Job finden</span>
					</button>
				</div>
			</form>
		</div>
	)
}

export default Search
