import { CareerLevel, EmploymentForm, EmploymentType, PostingSortingField, SortingOrder } from '../../../shared/generated/graphql'

const FILTER_OPTION_NAMES: {
	career_level: Record<CareerLevel, string>
	employment_type: Record<EmploymentType, string>
	employment_form: Record<EmploymentForm, string>
	starting_date: Record<string, string>
} = {
	career_level: {
		student: 'Berufseinsteiger:in',
		experienced: 'Berufserfahrene',
		careerchanger: 'Umsteiger:in',
		personnel_responsibility: 'Führungskraft',
	},
	employment_type: {
		full_time: 'Vollzeit',
		part_time: 'Teilzeit',
	},
	employment_form: {
		permanent_employment: 'Festanstellung',
		dual_studies: 'Duales Studium',
		apprenticeship: 'Ausbildung',
		working_student: 'Werkstudententätigkeit',
		temporary_employment: 'Befristete Anstellung',
		internship: 'Praktikum',
	},
	starting_date: {
		sofort: 'Sofort',
		'3monate': '< 3 Monaten',
		'3-6monate': 'zwichen 3 und 6 Monaten',
		'6monate': '> 6 Monaten',
	},
}

type FilterTypes = keyof typeof FILTER_OPTION_NAMES

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_SORTING = { field: PostingSortingField.Crdate, order: SortingOrder.Descending }
const DEFAULT_DISTANCE = 1
const MAX_DISTANCE = 1000
const DEFAULT_PAGE = 0

export { FILTER_OPTION_NAMES, DEFAULT_PAGE_SIZE, DEFAULT_SORTING, DEFAULT_DISTANCE, MAX_DISTANCE, DEFAULT_PAGE }
export type { FilterTypes }
