import { gql } from '@solid-primitives/graphql'

const CAREER_LIST = gql`
	query CareerList(
		$first: Int!
		$offset: Int!
		$discreteFilters: [DiscreteFilterInput!]!
		$closeLocations: [String!]!
		$rangeFilters: [RangeFilterInput!]!
		$query: String!
		$sorting: [PostingSorting!]
		$plz: String
		$distance: Int
	) {
		postings(
			offset: $offset
			first: $first
			filters: { discreteFilters: $discreteFilters, rangeFilters: $rangeFilters }
			searchQuery: $query
			sorting: $sorting
			plzSearchQuery: $plz
			plzSearchRange: $distance
		) {
			pageInfo {
				hasNextPage
			}
			totalCount
			items {
				locations {
					name
				}
				title
				employmentType
				employmentForm
				careerLevel
				url
				startingDate
				source {
					name
					nameShort
					logo {
						url
					}
				}
			}
			facets {
				__typename
				... on DiscreteFacet {
					label
					path
					options {
						selected
						value
						resultCount
						disabled
					}
				}
				... on RangeFacet {
					label
					path
					range {
						min
						max
					}
				}
			}
		}

		closeLocations(locations: $closeLocations, radius: 50, limit: 6) {
			name
		}
	}
`

const CAREER_SEARCH = gql`
	query CareerSearch($search: String!) {
		suggestPostings(searchQuery: $search)
	}
`

const CAREER_SIMILAR = gql`
	query CareerSimilar($first: Int!, $offset: Int!, $discreteFilters: [DiscreteFilterInput!]!) {
		similarPostings(offset: $offset, first: $first, filters: { discreteFilters: $discreteFilters }) {
			pageInfo {
				hasNextPage
			}
			totalCount
			items {
				locations {
					name
				}
				title
				employmentType
				employmentForm
				careerLevel
				url
				startingDate
				source {
					name
					nameShort
					logo {
						url
					}
				}
			}
		}
	}
`

const CAREER_LOCATIONS = gql`
	query CareerLocations($query: String!, $radius: Int!) {
		findLocations(query: $query, radius: $radius) {
			uid
			pid
			name
			addressCity
		}
	}
`

export { CAREER_LIST, CAREER_SEARCH, CAREER_SIMILAR, CAREER_LOCATIONS }
