import { Component } from 'solid-js'

type Props = {
	isDisabled?: boolean
	text: string
	onClick?: (event: MouseEvent) => void
}

const FilterBubble: Component<Props> = props => {
	return (
		<button
			type="button"
			class="flex items-center gap-2 rounded-md border border-yellow px-2 py-1 text-sm"
			disabled={props.isDisabled ?? false}
			onClick={e => props.onClick?.(e)}>
			<span class="filter-tag-text">{props.text}</span>
			<span class="tiny-button my-auto">
				<svg aria-hidden="true" viewBox="0 0 1 1">
					<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#close" />
				</svg>
			</span>
		</button>
	)
}

export default FilterBubble
