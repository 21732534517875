import { Component, createEffect, For, Show } from 'solid-js'
import DropdownMini from './DropdownMini'
import Arrow from '@/assets/icons/arrow-right.svg'

type Page = {
	name: string
	link: string
	isActive: boolean
}

export type BreadcrumbType = { siblings: Page[] } & Page

type Props = {
	breadcrumb: BreadcrumbType[]
}

const Breadcrumb: Component<Props> = props => {
	return (
		<nav
			class="breadcrumb breadcrumb-career j-breadcrumb grid-container breadcrumb-component !bg-transparent"
			role="navigation"
			aria-label="Sie befinden sich hier:">
			<div class="inner">
				<div class="breadcrumb-toggles j-breadcrumb-toggles">
					<For each={props.breadcrumb}>
						{(child, i) => (
							<Show
								when={i() === 0}
								fallback={
									<div class="breadcrumb-list-item" role="listbox">
										<a role="button" class="dropdown-button ico-class" href={child.link}>
											<Arrow class="arrow" />
											<span itemProp="name">{child.name}</span>
										</a>
									</div>
								}>
								<span
									class="breadcrumb-list-item breadcrumb-list-item--home"
									itemprop="itemListElement"
									itemtype="http://schema.org/ListItem">
									<meta itemprop="position" content="-1" />
									<a href="/" itemprop="item">
										<svg
											id="Icons"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 230.2 273.55"
											width="16px"
											height="16px">
											<path
												style={{
													fill: '#434451',
													'stroke-width': 0,
												}}
												d="m230.2,125.42v141.63c0,3.59-2.91,6.5-6.5,6.5h-75.51c-3.59,0-6.5-2.91-6.5-6.5v-62.98c0-7.67-2.66-13.78-7.9-18.17-4.7-3.92-11.39-6.17-18.37-6.17-12.77,0-26.51,7.61-26.51,24.34v62.98c0,3.59-2.91,6.5-6.5,6.5H6.5c-3.59,0-6.5-2.91-6.5-6.5V125.47c0-5.38,2.06-10.48,5.8-14.36L111.1,1.99c1.23-1.27,2.92-1.99,4.68-1.99h.02c1.77,0,3.47.73,4.69,2.02l104,109.14c3.68,3.86,5.71,8.92,5.71,14.26Z"
											/>
										</svg>
										<span class="visually-hidden" itemprop="name">
											Zurück zur Startseite
										</span>
									</a>
								</span>
							</Show>
						)}
					</For>
				</div>
			</div>
			<div class="gradient" />
		</nav>
	)
}

export default Breadcrumb
