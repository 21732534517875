import { Component, createSignal, For, Match, onCleanup, onMount, Show, Switch } from 'solid-js'
import { CareerListQuery } from '@/shared/generated/graphql'
import IconStartingDate from '@/assets/icons/career/BWEGT_HR_Icons_HR_Icon_Startzeitpunkt.svg'
import IconCareerLevel from '@/assets/icons/career/BWEGT_HR_Icons_HR_Icon_Berufserfahrung.svg'
import IconLocation from '@/assets/icons/career/BWEGT_HR_Icons_HR_Icon_Location.svg'
import IconEmploymentType from '@/assets/icons/career/BWEGT_HR_Icons_HR_Icon_Arbeitszeit.svg'
import { FILTER_OPTION_NAMES } from '@/components/career-list/shared/filter-configuration'

type Props = {
	posting: CareerListQuery['postings']['items'][0]
	selectedLocationFilterOptions: string[]
	isTablet: boolean
}

const Posting: Component<Props> = props => {
	return (
		<li>
			<a class="job-entry" href={props.posting.url}>
				<div class="result">
					{props.posting.source.logo && (
						<img class="logo" src={props.posting.source.logo.url} alt={props.posting.source.name} />
					)}
					<div class="result-text">
						<h3 class="h4 result-header">{props.posting.title}</h3>
						<ul class="keywords">
							<Show when={props.posting.startingDate}>
								<li class="starting-date">
									<IconStartingDate class="mr-1" />
									<span>{new Date(props.posting.startingDate).toLocaleDateString()}</span>
								</li>
							</Show>

							<li class="locations">
								<IconLocation />
								<span>
									<Switch>
										<Match when={props.posting.locations.length === 1}>
											{props.posting.locations[0].name}
										</Match>
										<Match when={props.posting.locations.length === 2}>
											{props.posting.locations[0].name} und 1 Standort
										</Match>
										<Match
											when={
												props.posting.locations.length > 2 &&
												props.selectedLocationFilterOptions.length === 0
											}>
											{props.posting.locations[0].name} und {props.posting.locations.length - 1} Standorte
										</Match>
										<Match
											when={
												props.selectedLocationFilterOptions.length === 1 &&
												props.posting.locations.length === 2
											}>
											{props.selectedLocationFilterOptions[0]} und 1 Standort
										</Match>
										<Match when={props.posting.locations.length > 2}>
											{props.selectedLocationFilterOptions.filter(l =>
												props.posting.locations.some(location => location.name === l)
											)[0] ?? props.selectedLocationFilterOptions[0]}{' '}
											und {props.posting.locations.length - 1} Standorte
										</Match>
									</Switch>
								</span>
							</li>

							<Show when={props.isTablet && props.posting.careerLevel.length > 0}>
								<li class="career-level">
									<IconCareerLevel class="mr-1" />
									<For each={props.posting.careerLevel}>
										{(type, index) => (
											<>
												<span>{FILTER_OPTION_NAMES['career_level']?.[type] ?? type}</span>
												<Show when={index() < props.posting.careerLevel.length - 1}>
													<span>,&nbsp;</span>
												</Show>
											</>
										)}
									</For>
								</li>
							</Show>

							<Show when={props.isTablet && props.posting.employmentType.length > 0}>
								<li class="employment-type">
									<IconEmploymentType class="mr-1" />
									<For each={props.posting.employmentType}>
										{(type, index) => (
											<>
												<span>{FILTER_OPTION_NAMES['employment_type']?.[type] ?? type}</span>
												<Show when={index() < props.posting.employmentType.length - 1}>
													<span>,&nbsp;</span>
												</Show>
											</>
										)}
									</For>
								</li>
							</Show>
						</ul>
					</div>
				</div>

				<button class="link-button" type="button">
					<img src="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/arrow-black.svg" alt="arrow" />
				</button>
			</a>
		</li>
	)
}

export default Posting
