import {
	batch,
	Component,
	createEffect,
	createMemo,
	createSignal,
	For,
	Match,
	onCleanup,
	onMount,
	Show,
	Switch,
	untrack,
} from 'solid-js'
import { CareerSimilarQuery, CareerSimilarQueryVariables } from '@/shared/generated/graphql'
import { CAREER_SIMILAR } from '@/shared/queries/career'
import { CareerStore } from '@/components/career-list/career-list'
import { useGraphQL } from '@/shared/context/GraphQLContext'
import ListSkeleton from '@/components/career-list/subcomponents/ListSkeleton'
import Posting from '@/components/career-list/subcomponents/Posting'
import LoadMoreIcon from '@/assets/icons/career/load-more.svg'

type Props = {
	store: CareerStore
}

const ListSimilarPostings: Component<Props> = props => {
	const newQueryPostingsSimilar = useGraphQL()

	const [offset, setOffset] = createSignal(0)
	const [pageSize, setPageSize] = createSignal(3)
	const [postings, setPostings] = createSignal<CareerSimilarQuery['similarPostings']['items']>([])

	const [data] = newQueryPostingsSimilar<CareerSimilarQuery, CareerSimilarQueryVariables>(
		CAREER_SIMILAR,
		// eslint-disable-next-line solid/reactivity
		(): CareerSimilarQueryVariables => ({
			discreteFilters: props.store.filters,
			offset: offset(),
			first: pageSize(),
		})
	)

	const selectedLocationFilterOptions = createMemo(() => {
		return props.store.filters.find(x => x.path === 'locations.name')?.options ?? []
	})

	createEffect(() => {
		if (props.store.filters) {
			setPostings([])
			batch(() => {
				setOffset(0)
				setPageSize(3)
			})
		}
	})

	createEffect(() => {
		if (data()?.similarPostings) {
			setPostings(x => [...x, ...(data()?.similarPostings.items ?? [])])
		}
	})

	const onLoadMore = () => {
		batch(() => {
			untrack(() => {
				setOffset(offset() + pageSize())
				setPageSize(10)
			})
		})
	}

	const [tablet, isTablet] = createSignal(false)

	const handlerResize = () => {
		if (window.matchMedia('(min-width: 768px)').matches) {
			isTablet(true)
		} else {
			isTablet(false)
		}
	}

	onMount(() => {
		window.addEventListener('resize', handlerResize)
		window.addEventListener('load', handlerResize)
	})

	onCleanup(() => {
		window.removeEventListener('resize', handlerResize)
		window.removeEventListener('load', handlerResize)
	})

	return (
		<Show when={postings().length > 0}>
			<section class="mt-8">
				<h3>Diese Jobs könnten dich auch interessieren</h3>
				<ul class="joblist">
					<For each={postings()}>
						{posting => (
							<Posting
								isTablet={tablet()}
								posting={posting}
								selectedLocationFilterOptions={selectedLocationFilterOptions()}
							/>
						)}
					</For>
					<Show when={data.loading}>
						<ListSkeleton pageSize={pageSize()} />
					</Show>
					<Show when={data()?.similarPostings.pageInfo.hasNextPage ?? false}>
						<button class="mx-auto mb-5 mt-4 flex flex-col uppercase" type="button" onClick={onLoadMore}>
							Mehr laden
							<LoadMoreIcon class="mx-auto mt-1 stroke-black" />
						</button>
					</Show>
				</ul>
			</section>
		</Show>
	)
}

export default ListSimilarPostings
