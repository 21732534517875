import { Accessor, Component, createEffect, createSignal, For, Setter } from 'solid-js'
import { DropdownData } from './Multiselect'

type Props = {
	data: DropdownData[]
	onSelect: (data: DropdownData[]) => void
}

const Chips: Component<Props> = props => {
	const [data, setData] = createSignal<DropdownData[]>([])

	const onSelect = (item: DropdownData) => {
		item.selected = !item.selected

		props.onSelect(data().filter(x => x.selected))
	}

	createEffect(() => {
		setData(props.data)
	})

	return (
		<div class="chip-container">
			<For each={data()}>
				{item => (
					<button
						onClick={() => onSelect(item)}
						class="chip"
						classList={{ active: item.selected }}
						aria-label={item.value}
						aria-selected={item.selected}
						role="option"
						disabled={item.disabled}>
						{item.value}
					</button>
				)}
			</For>
		</div>
	)
}

export default Chips
