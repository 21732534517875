import FilterBubble from '@/shared/components/FilterBubble'
import { Component, For, Show, createMemo } from 'solid-js'
import { FILTER_OPTION_NAMES } from '../shared/filter-configuration'
import { CareerStore } from '../career-list'

type FilterTypes = keyof typeof FILTER_OPTION_NAMES

type Props = {
	store: CareerStore
	onFilterOptionDisabled: (path: string, value: string) => void
	onResetLocationSearch: () => void
}

const FilterBubbles: Component<Props> = props => {
	const selectedFilterOptions = createMemo(() => props.store.visuallySelectedFilterOptions())

	const locationSearchFilterText = () => {
		const text = props.store.plz

		if (props.store.distance !== 1) {
			if (props.store.distance === 1000) {
				return `${text}, >100 km`
			}
			return `${text}, ${props.store.distance} km`
		}

		return text
	}

	return (
		<>
			<Show when={props.store.plz || props.store.distance !== 1}>
				<FilterBubble
					onClick={props.onResetLocationSearch}
					text={locationSearchFilterText()}
					isDisabled={props.store.postingsPagination.isLoading}
				/>
			</Show>
			<For each={selectedFilterOptions()}>
				{filter => (
					<FilterBubble
						text={(FILTER_OPTION_NAMES[filter.path as FilterTypes] as any)?.[filter.value] ?? filter.value}
						isDisabled={props.store.postingsPagination.isLoading}
						onClick={() => props.onFilterOptionDisabled(filter.path, filter.value)}
					/>
				)}
			</For>
		</>
	)
}

export default FilterBubbles
